import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";

// const cookies = new Cookies();


// export const getCustomerList = async (data) => { 
//     let token = sessionStorage.getItem(TOKEN_KEY);
//     console.log(token)
//     // let token = cookies.get(TOKEN_KEY);
//     let fd = new FormData()
//     for (var key in data) {
//         fd.append(key, data[key]);
//     }
//     let res = await axios({ method: "POST",data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}customerlist`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
//     return res
// };
export const getCustomerList = async () => {
    let token = sessionStorage.getItem(TOKEN_KEY);
    let res = await axios({ method: "GET", url: `${process.env.REACT_APP_API_ENDPOINT}customerlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

