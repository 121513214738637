import { Link, NavLink } from "react-router-dom";
import { LOGO } from "../../../config";
import { NAV_LINKS } from "./navlink";
import { useState } from "react";
import SubMenu from "./SubMenu";
import { AiOutlineLogout } from "react-icons/ai";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();
const SideBar = () => {
  const navigate = useNavigate();
  const [isSubMenuId, setIsSubMenuId] = useState(null);
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/", { replace: true });
  };
  return (
    <aside className=" w-[100px] h-full fixed left-0 bg-soft_gray z-20">
      <div className=" relative z-30 overflow-y-scroll overflow-x-hidden h-full">
        <div className="p-4 border-b bg-white justify-center text-center">
          {/* <img src={LOGO} alt="logo" /> */}

          <p className="font-semibold text-primary text-xl ">Naysha Farms</p>
        </div>
        <div className=" bg-white h-full">
          {NAV_LINKS.map((navItem, navIdx) => {
            const { icon, screen, link, hasSubMenu } = navItem;
            return (
              <div key={navIdx} className="text-center">
                {hasSubMenu ? (
                  <button
                    onClick={() => setIsSubMenuId(navIdx)}
                    className="hover:bg-gray-100 w-full py-3 inline-block relative z-30"
                  >
                    <div className="w-6 h-6 mx-auto">{icon}</div>
                    <span className="text-xs font-semibold uppercase">
                      {screen}
                    </span>
                  </button>
                ) : (
                  <NavLink
                    onClick={() => setIsSubMenuId(null)}
                    to={link}
                    className={`w-full py-3 inline-block relative z-40 hover:bg-gray-100`}
                  >
                    <div className="w-6 h-6 mx-auto">{icon}</div>
                    <span className="text-xs font-semibold uppercase">
                      {screen}
                    </span>
                  </NavLink>
                )}
                <SubMenu
                  open={hasSubMenu && navIdx === isSubMenuId}
                  onClose={() => setIsSubMenuId(false)}
                  data={navItem}
                />
              </div>
            );
          })}
          <button
            onClick={handleLogout}
            className="hover:bg-red-100 bg-red-500 w-full py-3 inline-block relative z-30"
          >
            <div className="w-6 h-6 mx-auto">
              <AiOutlineLogout className="w-full h-full text-white" />
            </div>
            <span className="text-xs font-semibold uppercase text-white">
              Logout
            </span>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
