import React, { useState } from "react";
import Layout from "../../components/layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AddBlogs from "./AddBlogs";
import UpdateBlogs from "./UpdateBlogs";
import Moment from "react-moment";
import DeleteBlogs from "./DeleteBlogs";
import useRead from "../../hooks/useRead";
import Errors from "../errors";
import Spinner from "../../components/spinner";
import { Alert, Snackbar } from "@mui/material";
import ReactPaginate from "react-paginate";
import Pagination from "../../components/elements/pagination";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

function Blogs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialData = {
    pageNo: searchParams.get("pageNo") || 0,
    limit: searchParams.get("limit") || 10,
    searchQuery: searchParams.get("searchQuery") || "",
  };
  const { data, paramsObject, setGetListParams } = useRead({
    url: "blogsListing",
    method: "GET",
    initialData,
    onError: (err) => {
      toast.error("There was an error while loading the data", {
        position: "top-right",
        duration: 3000,
      });
    },
  });
  const [openAddModal, setOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let copyPrams = {
      ...paramsObject,
      searchQuery: value,
    };
    setSearchParams(copyPrams);
    setGetListParams(copyPrams);
    // setSearcVal(value)
  };
  const handleOpenAddCoupon = () => {
    setOpenAddModal(true);
  };
  const handleModalClose = () => {
    setOpenAddModal(false);
    setIsOpenEditModal(false);
    setOpenDeleteModal(false);
  };
  const [singleBlogData, setSingleBlogData] = useState("");
  const handleOpenEditModal = (id) => {
    setCategoryId(id);
    setIsOpenEditModal(true);
    // let data = data.data.data.find(x => x.id === id)
    // setSingleBlogData(data)
  };
  const [categoryId, setCategoryId] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setCategoryId(id);
  };
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
  const deletedSuccess = () => {
    handleModalClose();
    setIsDeletedSuccess(true);
  };
  const handlePageClick = (val) => {
    let pageNo = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(pageNo);
    setSearchParams(pageNo);
  };
  return (
    <>
      {/* <Layout> */}
      <div className="blogs px-4">
        <div className="blog__head headingBorder__b">
          <h2 className="titleHeading">Blogs</h2>
        </div>
        <div className="blog_table py-5 space-y-5">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <input
                onChange={handleSearchVal}
                value={paramsObject?.search_query}
                type="text"
                name="searchQuery"
                placeholder="Search Blogs...."
                className="input"
              />
            </div>
            <div onClick={handleOpenAddCoupon}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                className="primaryBtn-outlined"
              >
                Create Blog
              </Button>
            </div>
          </div>
          {data?.isLoading ? (
            <Spinner />
          ) : data?.status === "error" ? (
            <Errors errorObject={data.error} inline />
          ) : (
            <>
              <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Blog Title
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Published On
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Status
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.data?.map((item, idx) => {
                      const { title, id, status, published_at } = item;
                      return (
                        <tr>
                          <td className="border-dashed border-t border-gray-200 userId">
                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                              {idx + 1}
                            </span>
                          </td>
                          <td className="border-dashed border-t border-gray-200 firstName">
                            <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                              {title}
                            </span>
                          </td>
                          <td className="border-dashed border-t border-gray-200 firstName">
                            <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                              {(
                                <Moment unix format={"MMM Do YYYY"}>
                                  {published_at}
                                </Moment>
                              ) || "---"}
                            </span>
                          </td>
                          <td className="border-dashed border-t border-gray-200 emailAddress">
                            <div className="flex items-center justify-center">
                              {status ? (
                                <span className="px-4 py-1 font-semibold rounded bg-green-700 text-green-200">
                                  Active
                                </span>
                              ) : (
                                <span className="px-4 py-1 font-semibold rounded bg-red-700 text-red-200">
                                  Disabled
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="border-dashed border-t border-gray-200 phoneNumber">
                            <div className="flex items-center space-x-5 justify-center">
                              <div>
                                <IconButton
                                  onClick={() => handleOpenEditModal(id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                              <div onClick={() => handleOpenDeleteModal(id)}>
                                                                    <IconButton>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className=" my-7">
                <Pagination
                  currentPage={+paramsObject.pageNo}
                  lengthofItems={data?.data?.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* </Layout> */}
      {openAddModal && (
        <AddBlogs open={openAddModal} handleClose={handleModalClose} />
      )}
      {isOpenEditModal && (
        <UpdateBlogs
          id={categoryId}
          open={isOpenEditModal}
          handleClose={handleModalClose}
        />
      )}
      <DeleteBlogs url={'delete-blog'} queryKey={'blogsListing'} open={openDeleteModal} onClose={handleModalClose} id={categoryId} onDelete={deletedSuccess} />
      <Snackbar
        open={isDeletedSuccess}
        autoHideDuration={6000}
        onClose={() => setIsDeletedSuccess(false)}
      >
        <Alert
          onClose={() => setIsDeletedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Blog Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default Blogs;
